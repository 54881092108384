// https://adrien.poupa.fr/creating-a-global-loader-component-in-vue-js/
export default {
  namespaced: true,
  state: {
    requestsPending: 0,
  },
  getters: {
    isLoading(state) {
      return state.requestsPending > 0;
    },
  },
  actions: {
    pending({ commit }) {
      commit("pending");
    },
    done({ commit }) {
      commit("done");
    },
  },
  mutations: {
    pending(state) {
      state.requestsPending++;
    },
    done(state) {
      if (state.requestsPending >= 1) {
        state.requestsPending--;
      }
    },
  },
};
