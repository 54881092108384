<template>
  <div id="app">
    <header class="mb-3">
      <div class="container">
        <b-navbar toggleable="sm" type="light" variant="default">
          <b-navbar-brand>MBW.AdmxSite</b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item to="/" exact>Admx</b-nav-item>
              <b-nav-item to="/Search">Search</b-nav-item>
              <b-nav-item to="/Compare">Compare</b-nav-item>
              <b-nav-item to="/About">About</b-nav-item>
            </b-navbar-nav>

            <router-view name="navbar-secondary"></router-view>

            <b-navbar-nav class="ml-auto align-items-center">
              <!-- 
                TODO: Inline search
                <b-nav-form>
                <b-form-input
                  size="sm"
                  class="mr-sm-2"
                  placeholder="Search"
                ></b-form-input>
                <b-button size="sm" class="my-2 my-sm-0" type="submit"
                  >Search</b-button
                >
              </b-nav-form> -->

              <b-nav-text
                v-if="apiIsLoading"
                class="badge badge-pill badge-light"
              >
                <b-icon-arrow-repeat animation="spin" /> Loading
              </b-nav-text>

              <router-view name="navbar-right"></router-view>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </header>

    <div class="container-fluid">
      <div class="d-flex justify-content-center w-100">
        <router-view name="default"></router-view>
      </div>
    </div>

    <footer class="border-top footer text-muted">
      <div class="container d-flex">
        <div>© {{ new Date().getFullYear() }} - Michael Bisbjerg</div>
        <div class="ml-auto">
          Inspired by <a href="https://getadmx.com">getadmx.com</a> &amp;
          <a href="https://www.windows-security.org/">windows-security.org</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<style>
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.os-host {
  height: 100%;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.router-link-active {
  font-weight: bold;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  line-height: 60px; /* Vertically center the text there */
}

.navbar-border-bottom {
  border-bottom: 1px solid #141414;
}

/* Highlighting */
.highlight {
  font-weight: bold;
}

/* Vue hiding */
[v-cloak] {
  display: none;
}
</style>

<script>
export default {
  computed: {
    apiIsLoading() {
      return this.$store.getters["ApiStore/isLoading"];
    },
  },
};
</script>
