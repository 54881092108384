import VueRouter from "vue-router";

VueRouter.prototype.pushSafe = function (location, onComplete, onAbort) {
  // https://stackoverflow.com/a/59431264/1246988
  this.push(location, onComplete, onAbort).catch((error) => {
    // Going to the same route should be "allowed"
    if (error.name != "NavigationDuplicated") {
      throw error;
    }
  });
};
