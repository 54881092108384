import Vue from "vue";
import moment from "moment";
import numeral from "numeral";

Vue.filter("formatDate", (value, format) => moment(value).format(format));
Vue.filter("join", (values, seperator) => values.join(seperator ?? ", "));
Vue.filter("base64toHex", (base64) =>
  Buffer.from(base64, "base64").toString("hex")
);
Vue.filter("toUpper", (value) => value.toUpperCase());
Vue.filter("toInt", (value) => parseInt(value));
Vue.filter("toLocaleString", (value) => value.toLocaleString());

Vue.filter("formatNumber", (value) => numeral(value).format("0,0"));
