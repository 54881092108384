/** Visit all elements in a tree, and apply func to each element like func(element) */
function visitor(tree, childProperty, func) {
  if (Array.isArray(tree)) {
    for (const item of tree) {
      visitor(item, childProperty, func);
    }
    return;
  }

  // Process childs first
  let children = tree[childProperty];
  if (children) {
    visitor(children, childProperty, func);
  }

  func(tree);
}

/** Compares all first-level properties of two objects */
function shallowEqual(a, b) {
  const keys1 = Object.keys(a);
  const keys2 = Object.keys(b);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}

/** Deduplicates an array */
function distinct(array) {
  return array.filter((value, idx, arr) => arr.indexOf(value) === idx);
}

/** Test if all values in an array are in a string */
function containsAll(haystack, needles) {
  return needles.every((needle) => haystack.indexOf(needle) >= 0);
}

/** Extracts the country code from a language code */
function getCountryCode(languageCode) {
  const splits = languageCode.split("-");
  if (splits.length === 2) return splits[1].toUpperCase();

  switch (languageCode) {
    case "ja":
      return "JP";
    case "ko":
      return "KR";
    default:
      // This is a likely just a country code
      return languageCode.toUpperCase();
  }
}

/** Selects a languageCode from a list, given a country code */
function getLanguageCodeByCountryCode(
  languageCodes,
  wantedCountryCode,
  fallbackLanguageCode
) {
  // Identify a language code to use, the user may previously have selected "de"
  // while this set might have "de-DE".
  let matchingLanguageCode = languageCodes.find(
    (x) => getCountryCode(x) === wantedCountryCode
  );
  if (matchingLanguageCode) return matchingLanguageCode;

  return fallbackLanguageCode;
}

export {
  visitor,
  shallowEqual,
  distinct,
  containsAll,
  getCountryCode,
  getLanguageCodeByCountryCode,
};
