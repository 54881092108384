import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import "./filters";

import * as Utility from "./utilities.js";
import * as UserSettings from "./user-settings";

// Router
import router from "./router";

// Router & Store hookup
router.beforeEach((to, from, next) => {
  if ("languageCode" in to.params) {
    const countryCode = Utility.getCountryCode(to.params.languageCode);
    UserSettings.setCountryCode(countryCode);
  }

  next();
});

// Common components
import {
  BootstrapVue,
  BootstrapVueIcons,
  BIconLaptop,
  BIconPerson,
  BIconSliders,
  BIconFolder,
} from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component("admx-icon-policy-machine", BIconLaptop);
Vue.component("admx-icon-policy-user", BIconPerson);
Vue.component("admx-icon-registry-machine", BIconSliders);
Vue.component("admx-icon-registry-user", BIconSliders);
Vue.component("admx-icon-files", BIconFolder);

import "overlayscrollbars/css/OverlayScrollbars.css";
import {
  OverlayScrollbarsPlugin,
  OverlayScrollbarsComponent,
} from "overlayscrollbars-vue";
Vue.use(OverlayScrollbarsPlugin);
Vue.component("overlay-scrollbars", OverlayScrollbarsComponent);

import { Fragment } from "vue-fragment";
Vue.component("v-fragment", Fragment);

// VueX
import Vuex from "vuex";
Vue.use(Vuex);

// API
import { ApiClient, ApiLoadingStore } from "./api";

const vuexStore = new Vuex.Store({
  modules: {
    ApiStore: ApiLoadingStore,
  },
});

// Vue app container
Vue.config.productionTip = false;

const vueApp = new Vue({
  router,
  render: (h) => h(App),
  store: vuexStore,
});

// API, inject into vue app
const api = new ApiClient(vueApp);
vueApp.$api = api;

// Load
vueApp.$mount("#app");
