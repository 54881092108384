import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/AdmxIndex.vue"),
  },
  {
    path: "/admx/view/:setId/:policyClass/:languageCode/:policyId?",
    name: "admx-set-view",
    components: {
      default: () => import("../views/AdmxSetView.vue"),
      "navbar-right": () => import("../components/AdmxSetLanguageDropdown.vue"),
      "navbar-secondary": () => import("../views/AdmxSetNavBar.vue"),
    },
  },
  {
    path: "/admx/registry/:setId/:policyClass/:languageCode",
    name: "admx-set-registry",
    components: {
      default: () => import("../views/AdmxSetRegistry.vue"),
      "navbar-right": () => import("../components/AdmxSetLanguageDropdown.vue"),
      "navbar-secondary": () => import("../views/AdmxSetNavBar.vue"),
    },
  },
  {
    path: "/admx/files/:setId",
    name: "admx-set-files",
    components: {
      default: () => import("../views/AdmxSetFiles.vue"),
      "navbar-secondary": () => import("../views/AdmxSetNavBar.vue"),
    },
  },
  {
    path: "/api/admx/download/:setId",
    name: "admx-set-download",
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/compare",
    name: "compare",
    component: () => import("../views/CompareIndex.vue"),
  },
  {
    path: "/compare/:setIdA/:setIdB/:languageCode",
    name: "compare-sets",
    components: {
      default: () => import("../views/CompareSets.vue"),
      "navbar-right": () => import("../components/AdmxSetLanguageDropdown.vue"),
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
];

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
