const urlRoot = process.env.VUE_APP_API_URL;

export default class {
  #vue;

  constructor(vue) {
    this.#vue = vue;
  }

  #get(url, body) {
    const vueApp = this.#vue;
    const apiStore = vueApp.$store;

    apiStore.dispatch("ApiStore/pending");

    const options = {
      method: "get",
    };

    if (body) {
      options.method = "post";
      options.body = JSON.stringify(body);
      options.headers = {
        "Content-Type": "application/json",
      };
    }

    return fetch(urlRoot + url, options)
      .then((data) => data.json())
      .then((data) => {
        apiStore.dispatch("ApiStore/done");
        return data;
      })
      .catch((reason) => {
        apiStore.dispatch("ApiStore/done");

        vueApp.$bvToast.toast(
          "An unexpected API error occurred on '" +
          options.method.toUpperCase() +
          " " +
          url +
          "'" +
          reason,
          {
            title: "API Error",
            variant: "danger",
            toaster: "b-toaster-bottom-right",
            autoHideDelay: 20000,
          }
        );
      });
  }

  getSets() {
    const url = `/sets`;
    return this.#get(url);
  }

  getSetFiles(setId) {
    const url = `/sets/${setId}/files`;
    return this.#get(url);
  }

  getSetLanguages(setId) {
    const url = `/sets/${setId}/languages`;
    return this.#get(url);
  }

  getSetTree(setId, policyClass, languageCode) {
    const url = `/sets/${setId}/${policyClass}/${languageCode}/tree`;
    return this.#get(url);
  }

  getSetSearch(setId, policyClass, languageCode, search) {
    const url = `/sets/${setId}/${policyClass}/${languageCode}/search/${encodeURI(
      search
    )}`;
    return this.#get(url);
  }

  getSetPolicy(setId, policyClass, languageCode, policyId) {
    const url = `/sets/${setId}/${policyClass}/${languageCode}/${policyId}/policy`;
    return this.#get(url);
  }

  getSetRegistry(setId, policyClass, languageCode) {
    const url = `/sets/${setId}/${policyClass}/${languageCode}/registry`;
    return this.#get(url);
  }

  getCompareIndex() {
    const url = `/sets/compare`;
    return this.#get(url);
  }

  getCompare(setIdA, setIdB, languageCode) {
    const url = `/sets/compare/${setIdA}/${setIdB}/${languageCode}`;
    return this.#get(url);
  }

  getSearchIndex() {
    const url = `/search`;
    return this.#get(url);
  }

  getSearch(languageCodes, sets, query) {
    const url = `/search`;
    const body = {
      selectedLanguages: languageCodes,
      selectedSets: sets,
      query: query,
    };

    return this.#get(url, body);
  }
}
